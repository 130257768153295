import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useBanks } from "components/page/admin/universe";
import { JsonResponseError, RootState } from "contorller";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { PromoCode, User, UserBillingPlan } from "./types";

export interface Product {
  id: string;
  active: true;
  product: { id: string; metadata?: { dtokens?: string } };
  recurring: {
    interval_count: number;
    trial_period_days: number;
    interval: "month" | "year";
  };
  lookup_key: string;
  unit_amount: number;
  requireCard?: boolean;
  unit_amount_decimal: string;
}

export enum CouponState {
  NONE,
  NOT_FOUND,
  APPLIED,
}

export interface AuthState {
  revision: number;
  user?: User;
  error?: JsonResponseError;
  appIsLoad: boolean;
  isLogged: boolean;
  danikaMaster: boolean;
  isFething: boolean;
  subscriptionEnabled: boolean;
  subscriptionIsFetching: boolean;
  promoCode: PromoCode | null;
  couponIsFetching: boolean;
  couponStatus: CouponState;
  loadProducts: boolean;
  errorSubscription?: JsonResponseError;
  products: Product[];
  forcedLogout: boolean;
}

export function useIsPremium() {
  return useSelector<RootState, boolean>(
    (state) =>
      state.auth.user?.admin ||
      state.auth.user?.billingPlanEnum === UserBillingPlan.PREMIUM ||
      state.auth.user?.billingPlanEnum === UserBillingPlan.PREMIUM_PLUS ||
      state.auth.user?.billingPlanEnum === UserBillingPlan.PREMIUM_TEST
  );
}
export function useIsPremiumPlus() {
  return useSelector<RootState, boolean>(
    (state) =>
      state.auth.user?.admin ||
      state.auth.user?.billingPlanEnum === UserBillingPlan.PREMIUM_PLUS
  );
}
export function useIsPremiumTest() {
  return useSelector<RootState, boolean>(
    (state) => state.auth.user?.billingPlanEnum === UserBillingPlan.PREMIUM_TEST
  );
}

export function useIsMediolanum() {
  return useSelector<RootState, boolean>(
    (state) => state.auth.user?.bank?.toLowerCase() === "mediolanum"
  );
}

export function isPremiumTest(user: User) {
  const date = user.premiumTestExpiration;
  if (date != null) {
    return (
      DateTime.fromISO(date).plus({ days: 1 }) > DateTime.now().startOf("day")
    );
  }
  return false;
}

export function useMyBank() {
  const myBank = useSelector<RootState, string | null>(
    (state) => state.auth.user?.bank ?? null
  );
  const { data } = useBanks();
  const banks = data?.bank ?? [];

  if (myBank === "mediolanum") {
    return "Mediolanum";
  }
  if (myBank === "banca generali") {
    return "Generali";
  }

  const lowerBank = myBank?.toLowerCase();
  const lowerBanks = banks.map((d) => d.toLowerCase());

  if (lowerBank != null && lowerBanks.includes(lowerBank)) {
    return myBank;
  } else {
    return null;
  }
}

const authState = createSlice({
  name: "auth",
  reducers: {
    load: (state) => {
      state.appIsLoad = true;
    },
    signin: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
      state.subscriptionEnabled = action.payload.user.active;
      state.isLogged = true;
      state.isFething = false;
      state.error = undefined;
    },
    signout: (state, action) => {
      state.user = undefined;
      state.subscriptionEnabled = false;
      state.isFething = false;
      state.isLogged = false;
      state.error = undefined;
      state.forcedLogout = action.payload;
    },
    error: (state, action) => {
      if (!action.payload)
        throw new Error("devi passarmi il payload con error");
      state.isFething = false;
      state.error = action.payload.error;
    },
    toggleBillingPlan: (state) => {
      if (state.user != null) {
        state.user.billingPlanEnum =
          state.user.billingPlanEnum === UserBillingPlan.BASE
            ? UserBillingPlan.PREMIUM
            : UserBillingPlan.BASE;
      }
    },
    toggleAdmin: (state) => {
      if (state.user != null) {
        state.user.admin = !(state.user.admin ?? false);
      }
    },
    reload: (state) => {
      state.revision = state.revision + 1;
    },
    toggleBeta: (state) => {
      if (state.user != null) {
        state.user.betaTester = !(state.user.betaTester ?? false);
      }
    },
    toggleDanikaMaster: (state) => {
      state.danikaMaster = !state.danikaMaster;
    },
    isFething: (state) => {
      state.isFething = true;
      state.error = undefined;
    },
    listProducts: (state, action: PayloadAction<{ products: Product[] }>) => {
      state.products = action.payload.products;
      state.loadProducts = true;
    },
    fetchRequestSubscription: (state) => {
      state.subscriptionIsFetching = true;
    },
    getCouponCode: (state) => {
      state.couponIsFetching = true;
      state.promoCode = null;
    },
    resetPaymentError: (state) => {
      state.errorSubscription = undefined;
    },
    enableUser: (state) => {
      state.errorSubscription = undefined;
      if (state.user != null) {
        state.user.active = true;
        state.subscriptionEnabled = true;
      }
    },
    setCustomBank: (state, action: PayloadAction<{ bank: string }>) => {
      if (state.user != null) {
        state.user.bank = action.payload.bank;
      }
    },
    doneCouponCode: (
      state,
      action: PayloadAction<{ code: PromoCode | null; state: CouponState }>
    ) => {
      state.couponIsFetching = false;
      state.promoCode = action.payload.code;
      state.couponStatus = action.payload.state;
    },
    resetCouponCode: (state) => {
      state.promoCode = null;
      state.couponStatus = CouponState.NONE;
    },
    donePayment: (state) => {
      state.errorSubscription = undefined;
      state.subscriptionIsFetching = false;
    },
    errorPayment: (state, action: PayloadAction<JsonResponseError>) => {
      state.errorSubscription = action.payload;
      state.subscriptionIsFetching = false;
    },
  },
  initialState: {
    revision: 0,
    appIsLoad: false,
    isLogged: false,
    products: [],
    loadProducts: false,
    subscriptionEnabled: false,
    subscriptionIsFetching: false,
    couponIsFetching: false,
    couponStatus: CouponState.NONE,
    isFething: false,
    promoCode: null,
    danikaMaster: true,
    forcedLogout: false,
  } as AuthState,
});

export const authReducer = authState.reducer;
export const AuthActions = authState.actions;
