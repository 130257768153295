import { JsonResponseError } from "contorller";
import { authController } from "contorller/auth/controller";
import { ControllerSimulation, Snap } from "./types";
import { decodeFromBlob } from "./utils";

interface GenericResponse {
  index: number;
  total: number;
  snaps: Snap[];
}

export interface Puppet<T, R> {
  callbackReady(): void;
  callbackNotReady(): void;
  getUrl(): Promise<string>;
  startRolling(request: T): void;
  startRollingFallback(request: T): Promise<R[]>;
  recvRolling(result: R[], allResults: R[]): Promise<void>;
  error(error: JsonResponseError): void;
  clear(): void;
}

// TODO: Da completare classe che unifica tutte le simulazioni
export class BaseController<T, R extends GenericResponse>
  implements ControllerSimulation<T, R>
{
  private socket?: WebSocket;
  private name: string;
  private puppet?: Puppet<T, R>;
  private simulations: R[] = [];

  constructor(name: string, puppet: Puppet<T, R>) {
    this.puppet = puppet;
    this.name = name;
    this.setWebsocket();
  }
  jsonDownload() {
    throw new Error("Method not implemented.");
  }
  csvDownload() {
    throw new Error("Method not implemented.");
  }
  getName(): string {
    return this.name;
  }
  getSimulations(): R[] {
    return this.simulations;
  }
  private open(e: Event) {
    console.log("connession stabilita con servizio", this.name);
    if (this.puppet) {
      this.puppet.callbackReady();
    }
  }
  private close(e: Event) {
    console.log("chiusa connessione con servizio", this.name);
    if (this.puppet) {
      this.puppet.callbackNotReady();
    }
    this.reconnect(2);
  }
  private error(e: Event) {
    console.error("Errore con servizio ", this.name, e);
    if (this.puppet) {
      this.puppet.callbackNotReady();
    }
    this.reconnect(2);
  }

  async message(e: MessageEvent<string | Blob>) {
    let datas: R[] = [];
    try {
      if (typeof e.data !== "string") {
        datas = (await decodeFromBlob<R[]>(e.data)) as R[];
      } else {
        let j = JSON.parse(e.data) as JsonResponseError;
        if (this.puppet) {
          this.puppet.error(j);
        }
        return;
      }
    } catch (e) {
      console.error("ERRORE: ", e);
    }
    this.simulations.push(...datas);
    if (this.simulations.length === 0) {
      this.rollingStart = Date.now();
    }
    if (this.simulations.length === datas[0].total) {
      console.log(
        this.getName(),
        " start:",
        Date.now() - this.start,
        "ms",
        "rollingStart:",
        Date.now() - this.rollingStart,
        "ms",
        "results:",
        this.simulations
      );
      this.simulations = this.simulations.sort((a, b) => {
        return a.index - b.index;
      });
    }

    if (this.puppet) {
      this.puppet.recvRolling(datas, this.simulations);
    }
  }

  //TODO: da controllare se funziona correttamente questa funzione.
  private reconnect(seconds: number) {
    setTimeout(() => {
      this.setWebsocket().catch((e) => {
        this.reconnect(seconds);
      });
    }, seconds * 1000);
  }

  private async setWebsocket(): Promise<void> {
    if (this.puppet) {
      this.socket = new WebSocket(await this.puppet.getUrl());
      this.socket.addEventListener("open", (e) => this.open(e));
      this.socket.addEventListener("close", (e) => this.close(e));
      this.socket.addEventListener("error", (e) => this.error(e));
      this.socket.addEventListener("message", (e) => this.message(e));
    }
  }

  private start: number = 0;
  private rollingStart: number = 0;

  //TODO: id può essere eliminato?
  async calc(id: string, request: T): Promise<void> {
    this.start = Date.now();
    const bank = authController().getUser()?.bank?.toUpperCase();
    const isBankWebsocketEnabled = bank !== "BNL";
    const isWebsocketDisabled =
      window.localStorage.getItem("DISABLE_WEBSOCKET") === "true";
    let isWebsocketSim =
      isBankWebsocketEnabled ||
      (this.socket?.readyState === WebSocket.OPEN && !isWebsocketDisabled);
    console.log(
      this.name,
      " request:",
      request,
      isWebsocketSim ? "WS" : "HTTP"
    );

    if (this.puppet) {
      if (!isWebsocketSim) {
        const res = await this.puppet.startRollingFallback({
          ...request,
        });
        if (res != null) {
          this.simulations = res;
        } else {
          this.simulations = [];
          isWebsocketSim = true;
        }
      }
      if (isWebsocketSim) {
        if (!this.socket) throw new Error("Il web socket non è settato");
        this.puppet.startRolling({ ...request });
        this.simulations = [];
        this.socket.send(JSON.stringify(request));
      }
    }
  }

  getResult(id: string): R | undefined {
    throw new Error("Method not implemented.");
  }
  clearResult(id: string): void {
    throw new Error("Method not implemented.");
  }
  getAllResults(): R[] {
    return this.simulations;
  }
  clearAllResults(): void {
    this.simulations = [];
    if (this.puppet) {
      this.puppet.clear();
    }
  }
  //   jsonDownload() {
  //     throw new Error("Method not implemented.");
  //   }
  //   csvDownload() {
  //     throw new Error("Method not implemented.");
  //   }
}
