import { config } from "config";
import { JsonResponseError, store } from "contorller";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import {
  importFromExcelFile,
  importIsinString,
  ParsedFund,
  parseFinecoInput,
  parseMediolanumInput,
  runEmpirichQuery,
} from "contorller/utils";
import { difference, uniq } from "lodash";
import { Fund, FundsActions } from "./redux";

interface RefreshFundHistoryResponse {
  start: string;
  end: string;
  days: number;
}

interface RefreshFundResponse {
  id: string;
  isDistribution: boolean;
  history?: RefreshFundHistoryResponse;
  historyWithoutReturn?: RefreshFundHistoryResponse;
}

//const url = config().URL + "/funds"
interface IsinImporterResult {
  missingIsins: string[];
}
export interface FundsController {
  search(name: string): Promise<void>;
  reset(): void;
  refreshFund(id: string): Promise<RefreshFundResponse>;
  isinImporter(text: string): Promise<IsinImporterResult>;
  retrieveFundsFromIsin(
    text: string,
    options?: { avoidIfSingleFund?: boolean }
  ): Promise<{
    funds: Fund[];
    error?: JsonResponseError;
    isins: string[];
    missingIsins: string[];
  }>;
  customImporter(
    text: string,
    bank: string,
    shouldAppendAmount: boolean
  ): Promise<IsinImporterResult>;
  mediolanumImporter(
    text: string,
    shouldAppendAmount: boolean
  ): Promise<IsinImporterResult>;
  importFromExcel(rows: string | number[][]): Promise<IsinImporterResult>;
}

export const fundController = () =>
  concreteFundController.instance(authController().getAuther());

class concreteFundController implements FundsController {
  private auther: Auther;
  private constructor(auther: Auther) {
    this.search_aborder = new AbortController();
    this.auther = auther;
  }
  reset(): void {}

  private static _instance: concreteFundController;
  static instance(auther: Auther): FundsController {
    if (!concreteFundController._instance) {
      concreteFundController._instance = new concreteFundController(auther);
    }
    return concreteFundController._instance;
  }
  private search_aborder?: AbortController;
  async search(name: string): Promise<void> {}

  async refreshFund(id: string): Promise<RefreshFundResponse> {
    return runEmpirichQuery({
      endpoint: `/v1/admin/histories/update/${id}`,
      method: "GET",
    });
  }

  async retrieveFundsFromIsin(
    text: string,
    options?: { avoidIfSingleFund?: boolean }
  ): Promise<{
    funds: Fund[];
    error?: JsonResponseError;
    isins: string[];
    missingIsins: string[];
  }> {
    const isins = uniq(importIsinString(text.toUpperCase()));
    if (isins.length === 0) {
      return { funds: [], isins: [], missingIsins: [] };
    }

    if (options?.avoidIfSingleFund === true && isins.length === 1) {
      return { funds: [], isins: [], missingIsins: [] };
    }

    const response = await fetch(config().URL + "/funds/isins", {
      method: "POST",
      body: JSON.stringify({ isins }),
    });
    if (response.status === 200) {
      const data: { funds: Fund[] } = await response.json();
      const missingIsins = difference(
        isins,
        data.funds?.map((d) => d.isin) ?? []
      );
      return { funds: data.funds, isins, missingIsins };
    } else {
      const error: JsonResponseError = await response.json();
      return { funds: [], error, isins, missingIsins: [] };
    }
  }

  async isinImporter(text: string): Promise<IsinImporterResult> {
    const { funds, error, isins } = await this.retrieveFundsFromIsin(text);

    if (error == null) {
      store.dispatch(FundsActions.noError());
      store.dispatch(FundsActions.appendInImporter({ funds }));
      store.dispatch(FundsActions.endIsinLoad());
      const missingIsins = difference(isins, funds?.map((d) => d.isin) ?? []);
      return { missingIsins };
    } else {
      store.dispatch(FundsActions.error({ error }));
      return { missingIsins: isins };
    }
  }

  async customImporter(
    text: string,
    bank: string,
    shouldAppendAmount: boolean = false
  ): Promise<IsinImporterResult> {
    if (bank.toUpperCase() === "FINECO") {
      return this.finecoImporter(text, shouldAppendAmount);
    } else {
      return this.mediolanumImporter(text, shouldAppendAmount);
    }
  }

  async finecoImporter(
    text: string,
    shouldAppendAmount: boolean = false
  ): Promise<IsinImporterResult> {
    console.log(text);
    const funds = parseFinecoInput(text);
    return this.importParsedFunds(funds, shouldAppendAmount);
  }

  async importParsedFunds(
    funds: ParsedFund[],
    shouldAppendAmount: boolean = false
  ): Promise<IsinImporterResult> {
    const isins = uniq(funds.map((d) => d.isin));
    const response = await fetch(config().URL + "/funds/isins", {
      method: "POST",
      body: JSON.stringify({ isins }),
    });

    if (response.status === 200) {
      const data: { funds: Fund[] } = await response.json();
      if (data.funds) {
        data.funds.forEach((f) => {
          let matchedFunds = funds.filter((e) => e.isin === f.isin);
          let t;
          if (shouldAppendAmount) {
            t = matchedFunds.reduce(
              (prev, next) => {
                return {
                  amount: prev.amount + next.amount,
                  quote: prev.quote + next.quote,
                };
              },
              { amount: 0, quote: 0 }
            );
          } else {
            t = {
              amount: matchedFunds?.[0].amount,
              quote: matchedFunds?.[0].quote,
            };
          }

          f.amount = t.amount;
          f.quote = t.quote;
        });
      }
      store.dispatch(FundsActions.noError());
      store.dispatch(
        FundsActions.appendInImporter({ ...data, shouldAppendAmount })
      );
      store.dispatch(FundsActions.endIsinLoad());

      const missingIsins = difference(
        isins,
        data?.funds?.map((d) => d.isin) ?? []
      );
      return { missingIsins };
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(FundsActions.error({ error: data }));
      return { missingIsins: [] };
    }
  }

  async mediolanumImporter(
    text: string,
    shouldAppendAmount: boolean = false
  ): Promise<IsinImporterResult> {
    const funds = parseMediolanumInput(text).filter((f) => f.amount > 0);
    return this.importParsedFunds(funds, shouldAppendAmount);
  }

  async importFromExcel(
    rows: string | number[][]
  ): Promise<IsinImporterResult> {
    const funds = importFromExcelFile(rows);
    const isins = funds.map((d) => d.isin);

    const response = await fetch(config().URL + "/funds/isins", {
      method: "POST",
      body: JSON.stringify({ isins: funds.map<string>((e) => e.isin) }),
    });

    if (response.status === 200) {
      const data: { funds: Fund[] } = await response.json();
      if (data.funds) {
        data.funds.forEach((f) => {
          let t = funds.filter((e) => e.isin === f.isin);
          if (t.length > 0) {
            f.amount = t[0].amount;
            f.quote = t[0].quote;
          }
        });
      }
      store.dispatch(FundsActions.noError());
      store.dispatch(FundsActions.appendInImporter(data));
      store.dispatch(FundsActions.endIsinLoad());
      const missingIsins = difference(
        isins,
        data?.funds?.map((d) => d.isin) ?? []
      );
      return { missingIsins };
    } else {
      const data: JsonResponseError = await response.json();
      store.dispatch(FundsActions.error({ error: data }));
      return { missingIsins: [] };
    }
  }
}
