import { IconName, Intent, ISliderBaseProps } from "@blueprintjs/core";
import { List } from "components/context/ListContext";
import { Fund } from "contorller/fund/redux";
import { Portfolio } from "contorller/portfolio/types";
import { get, snakeCase } from "lodash";
import { useTranslation } from "react-i18next";

export interface DanikaAlert {
  title: string;
  message: string;
  intent: Intent;
  icon?: IconName;
  confirmButtonText?: string;
  cancelButtonText?: string;
}

export enum DanikaPenaltyLevels {
  NONE = 0,
  WEAK = 1,
  MEDIUM = 2,
  STRONG = 3,
}

export interface MetricValue {
  min?: number;
  max?: number;
  best?: number;
  worst?: number;
  weight?: number;
  enable_malus?: boolean;
  better_penalty_lvl?: DanikaPenaltyLevels;
  worse_penalty_lvl?: DanikaPenaltyLevels;
}

export enum ETFFilter {
  UNDEFINED,
  IGNORE_ETF,
  ONLY_ETF,
}
export enum SubQueryFilter {
  UNDEFINED,
  IGNORE,
  ONLY,
}

export interface Query {
  type: "fund" | "super_category" | "category" | "society" | "list" | "banks";
  occurrencies: number;
  weight?: number;
  prefilter?: boolean;
  values: string[];
  exclude?: boolean;
  etf?: ETFFilter;
  distribution?: SubQueryFilter;
  reserved?: SubQueryFilter;
  context?: Fund | List | null;
}

export interface MetricsState {
  ann_y_eld: MetricValue;
  volatility: MetricValue;
  sharpe: MetricValue;
  downside_risk: MetricValue;
  sortino: MetricValue;
  max_drawdown: MetricValue;
  recovery_period: MetricValue;
  var95_1Y: MetricValue;
  var99_1Y: MetricValue;
}

export interface PortfolioState {
  size: number;
  amount: number;
  max_similarity: number;
  fund_max_weight: number;
  fund_min_weight: number;
}
export interface SimulationState {
  population_size: number;
  top_ports_percentage: number;
  top_ports_to_show: number;
  early_exit_percentage: number;
  duration_years: number;
  duration_rolling: number;
  back_to_date: string;
  number_of_generations_to_run: number;
  free_risk: number;
  nav_delay_days: number;
  proxy: boolean;
  rolling_frequency: number;
  correlation_cut_threshold: number;
  max_age_penalty: number;
  max_proxy_penalty: number;
  max_diversification_bonus: number;
  max_globalization_bonus: number;
}

export interface MutationState {
  enable_treshold: number;
  super_category_treshold: number;
  category_treshold: number;
  fund_treshold: number;
  weight_treshold: number;
  crossover_treshold: number;
}

export interface UpdateMessage {
  text: string;
  error: boolean;
  code: String;
  finished: boolean;
  payload: unknown;
  generation: number;
}

export const METRICS = [
  "ann_y_eld",
  "volatility",
  "sharpe",
  "downside_risk",
  "sortino",
  "max_drawdown",
  "recovery_period",
  "var95",
  "var99",
];

export interface DanikaResult {
  portfolio: Portfolio;
  ann_y_eld: number;
  y_eld: number;
  score: number;
  volatility: number;
  sharpe: number;
  is_from_creator?: number;
  downside_risk: number;
  sortino: number;
  max_drawdown: number;
  recovery_period: number;
  var95_1Y: number;
  var99_1Y: number;
}

// ann_y_eld: { best: 10, worst: 5, weight: 0, enable_malus: true },
//     volatility: { best: 1, worst: 3, weight: 0, enable_malus: true },
//     sharpe: { best: 1, worst: 3, weight: 0, enable_malus: true },
//     downside_risk: { best: 1, worst: 5, weight: 0, enable_malus: true },
//     sortino: { best: 1, worst: 3, weight: 0, enable_malus: true },
//     max_drawdown: { best: 10, worst: 5, weight: 0, enable_malus: true },
//     recovery_period: { best: 100, worst: 300, weight: 0, enable_malus: true },
//     var95: { best: 1, worst: 5, weight: 0, enable_malus: true },
//     var99: { best: 1, worst: 5, weight: 0, enable_malus: true },

export interface SliderProps extends ISliderBaseProps {
  reversed?: boolean;
  negative?: boolean;
}

export const METRICS_RANGE: { [key: string]: SliderProps } = {
  ann_y_eld: { min: 0, max: 20, stepSize: 0.1, labelStepSize: 2 },
  volatility: { min: 1, max: 25, stepSize: 0.5, reversed: true },
  max_drawdown: {
    min: 0,
    max: 60,
    stepSize: 1,
    labelStepSize: 2,

    reversed: true,
    negative: true,
  },
  sharpe: { min: -3, max: 5, stepSize: 0.1 },
  sortino: { min: -3, max: 5, stepSize: 0.1 },
  downside_risk: {
    min: 0,
    max: 50,
    labelStepSize: 2,
    stepSize: 0.5,
    reversed: true,
  },
  recovery_period: {
    min: 0,
    max: 2000,
    stepSize: 5,
    labelStepSize: 100,
    reversed: true,
  },
  var95_1Y: { min: 0, max: 50, labelStepSize: 2, reversed: true },
  var99_1Y: { min: 0, max: 50, labelStepSize: 2, reversed: true },
};

export default function useMetricsExplanation(): { [key: string]: string } {
  const { t } = useTranslation();

  const downside_risk = t(
    "Il Downside Risk, o Volatilità Negativa Annualizzata, quantifica i ribassi di prezzo, rispetto ad un rendimento minimo accettabile ( MAR ), in un dato intervallo temporale. Consiste nella deviazione standard di tutti i rendimenti giornalieri ( di un dato periodo ) inferiori ad un minimo accettabile, quantificato come il massimo tra zero ed il tasso annuo Risk Free €STR (Euro short-term rate) giornalizzato, moltiplicata per un fattore costante che considera un anno come composto da 250 giorni."
  );
  const ann_y_eld = t(
    "Indica la variazione percentuale media annua del patrimonio nel periodo di tempo considerato, calcolata secondo il metodo di capitalizzazione composta total return."
  );
  const divsFlux = t(
    "Indica la percentuale di dividendi distribuiti dallo strumento, nell’intervallo di tempo considerato, a fronte di un ipotetico investimento di 100€."
  );
  const var95 = t(
    "Il VaR, o Value at Risk, indica la perdita potenziale di una posizione di investimento in un certo orizzonte temporale. Calcolata tramite l'approccio parametrico, sfrutta la distribuzione normale delle volatilità giornaliere dell’intero storico dello strumento per proiettare, con un grado di affidabilità del 95%, la massima perdita percentuale nel futuro lasso temporale preso in esame. Ad esempio, un VaR 95% a 36 mesi pari al 7% indica che, nel 95% dei casi possibili, tra 36 mesi lo strumento non registrerà un ribasso superiore al 7% rispetto al valore odierno."
  );
  const var99 = t(
    "Il VaR, o Value at Risk, indica la perdita potenziale di una posizione di investimento in un certo orizzonte temporale. Calcolata tramite l'approccio parametrico, sfrutta la distribuzione normale delle volatilità giornaliere dell’intero storico dello strumento per proiettare, con un grado di affidabilità del 99%, la massima perdita percentuale nel futuro lasso temporale preso in esame. Ad esempio, un VaR 99% a 36 mesi pari al 7% indica che, nel 99% dei casi possibili, tra 36 mesi lo strumento non registrerà un ribasso superiore al 7% rispetto al valore odierno."
  );

  const y_eld = t(
    "Indica la percentuale di variazione del patrimonio nel periodo di tempo considerato."
  );

  const labels = {
    ann_y_eld,
    annualized_return: ann_y_eld,
    divsFlux,
    y_eld,
    divs_flux: divsFlux,
    volatility: t(
      " La Volatilità, o Volatilità Annualizzata, è un indice di rischio che misura l’incertezza circa i futuri movimenti del prezzo dello strumento. Questa è calcolata come deviazione standard dei rendimenti giornalieri ( Volatilità Giornaliera ) dello strumento, nel periodo in esame, moltiplicata per un fattore costante che considera un anno come composto da 250 giorni."
    ),
    sharpe: t(
      "L’indice di Sharpe rappresenta il rapporto tra l’extra-rendimento e il rischio dello strumento in un dato periodo. In Empirich, misuriamo l'extra-rendimento come la differenza tra il rendimento annualizzato dello strumento ed il massimo tra il tasso annuo Risk Free €STR ( Euro short-term rate ) e zero, mentre l’indice di rischio utilizzato è la Volatilità annualizzata dello strumento."
    ),
    sortino: t(
      "L’indice di Sortino rappresenta il rapporto tra l’extra-rendimento e il rischio che lo strumento oscilli al di sotto di un minimo accettabile in un dato periodo. In Empirich, misuriamo l'extra-rendimento come la differenza tra il rendimento annualizzato dello strumento ed il massimo tra il tasso annuo Risk Free €STR ( Euro short-term rate ) e zero, mentre l’indice di rischio utilizzato è il Downside Risk annualizzato dello strumento."
    ),
    max_drawdown: t(
      "Rappresenta la massima perdita percentuale osservabile nello storico dello strumento, nell'arco temporale in esame."
    ),
    downside_risk,
    down_side_risk: downside_risk,
    recovery_period: t(
      "Ove presente, indica il massimo numero di giorni che lo strumento impiega per recuperare definitivamente qualsiasi ribasso avvenuto nell’arco temporale in esame."
    ),

    var95_1Y: var95,
    var99_1Y: t(
      "Il VaR, o Value at Risk, indica la perdita potenziale di una posizione di investimento in un certo orizzonte temporale. Calcolata tramite l'approccio parametrico, sfrutta la distribuzione normale delle volatilità giornaliere dell’intero storico dello strumento per proiettare, con un grado di affidabilità del 99%, la massima perdita percentuale nel futuro lasso temporale preso in esame. Ad esempio, un VaR 99% a 36 mesi pari al 7% indica che, nel 99% dei casi possibili, tra 36 mesi lo strumento non registrerà un ribasso superiore al 7% rispetto al valore odierno."
    ),
    return: y_eld,
    var95,
    var99,
    va_r_95: var95,
    va_r_99: var99,
  };

  const keys = Object.keys(labels);
  return keys.reduce((prev, next) => {
    return {
      ...prev,
      [snakeCase(next)]: get(labels, next),
    };
  }, labels);
}
