import { Fund } from "contorller/fund/redux";
import { SpotOperation } from "./PAC/redux";

export interface ControllerSimulation<T, R> {
  calc(id: string, request: T): Promise<void>;
  getResult(id: string): R | undefined;
  clearResult(id: string): void;
  getAllResults(): R[];
  clearAllResults(): void;
  getName(): string;
  jsonDownload(): any;
  csvDownload(): any;
}

export interface Investment {
  money: number;
  unix: number;
  fund?: string;
}

export interface StepinRequest {
  ingress: number[];
  multiple: number[];
  differiment: number;
}

export interface SimulationWithSpotOperation {
  weights: number[];
  days: {
    unix: number;
    closes: number[];
    invested: number[];
    earns: number[];
    funds: number[];
    rate: number[];
    quotes: number[];
  }[];
  spots: SpotOperation[];
}
export interface GoalRequest {
  //unix timestamp dei singoli invesitmenti
  investmentDate: number[];
  // quantitativo di soldi metti per ogni singolo investimento
  money: number[];
  // fine investimento
  endInvestment: number;
  //Montante finale
  exit: number;
}

export interface GoalResponse {
  value: number;
  error: string;
}

export interface MultipleGoalRequest {
  requests: GoalRequest[];
}
export interface MultipleGoalResponse {
  responses: GoalResponse[];
}
export interface StepoutRequest {
  earn: number;
}

interface startEnd {
  start: number;
  end: number;
}
interface calcBase extends startEnd {
  funds: FundWeight[];
}
interface investmentCycle {
  days: number[];
  initialInvestment: number;
  durationInvestment: number;
  stepIn?: StepinRequest;
}

export interface PACRequest extends calcBase, investmentCycle {
  differimentMounth: number;
  rate: number;
  monthFrequency: number;
}

export interface MESRequest extends calcBase {
  initialInvestment: number;
  days?: number;
}
export interface PICRequest extends calcBase {
  rebalance: number;
  investments: Investment[];
  name: string;
  initialInvestment: number;
  showInflation?: boolean;
  showInterestsCompare?: boolean;
  fixedRate?: number;
  annualCommission?: number;
}
export interface IISRequest extends calcBase, investmentCycle {
  monetary: string;
  totalInvestment: number;
  durationIIS: number;
  continue: boolean;
  stepout: StepoutRequest;
  money?: number;
}

export interface DataStepIn {
  // fondo coinvolto
  id: string;
  // money già moltiplicato quindi reale
  money: number;
  // Multiplo che ha moltuiplicato la rata
  multiple: number;
  close: number;
  vavg: number;
}
export interface DataStepOut {
  // id del fondo coinvolto
  id: string;
  // guadagno in moneta
  earn: number;
  close: number;
  vavg: number;
}

export interface Snap {
  closes: number[];
  unix: number;
  funds: number[];
  quotes: number[];
  moneyExchange?: number;
  investedMoney: number;
  disinvestedMoney: number;
  stepIn?: DataStepIn[];
  stepOut?: DataStepOut[];
  vmcIn?: number[];
  vmcOut?: number[];
}

export interface Simulation {
  index: number;
  total: number;
  snaps: Snap[];
  annual: number;
  absolute: number;
}

export interface FundWeight {
  id: string;
  isin?: string;
  fund?: Fund;
  weight: number;
  name: string;
  start: number;
  end: number;
  // proxy?: Date;
}

export interface pacInvestment {
  unix: number;
  money: number;
  clsoes: number[];
  values: number[];
  investments: number[];
}
export interface snapEarning {
  id: string;
  days: {
    unix: number;
    earns: number[];
    closes: number[];
    funds: number[];
    quotes: number[];
    invested: number;
    rate: number;
  }[];
  investment: Investment[];
}

export interface PACResponse {
  index: number;
  total: number;
  snaps: Snap[];
  annual: number;
  absolute: number;
  request: PACRequest;
  simulationsWithSpots: SimulationWithSpotOperation[];
}

//responses

export type IISResponse = {
  simulations: Simulation[];
  request?: IISRequest;
};
export interface PICResponse {
  snaps: Snap[];
  name: string;
  request: PICRequest;
  id: string;
  annual: number;
  absolute: number;
}

export interface EarnTable {
  years?: number;
  absolute: { min: number; max: number; avg: number };
  annual: { min: number; max: number; avg: number };
}

export const DEFAULT_EARN_TABLE: EarnTable = {
  years: 0,
  absolute: { min: 0, max: 0, avg: 0 },
  annual: { min: 0, max: 0, avg: 0 },
};
